<template>
  <fragment>
    <div class="payrollTableHeader">
      <h6 class="mb-5" v-if="!showDocuments">
        Lista de empleados de {{ enterprise.name }}
      </h6>
      <h6 class="mb-5" v-if="showDocuments">
        Lista de documentos
      </h6>
    </div>
    <div v-show="!showDocuments">
      <b-modal
        id="modal-select-single-file"
        title="Seleccione un documento"
        @ok="uploadSingleFile"
      >
        <trazo-form-label-slot
          labelPosition="top"
          :required="true"
          label="Seleccione un tipo de documento"
        >
          <a-multiselect
            name="document"
            track-by="id"
            label="description"
            v-model="document"
            placeholder="Seleccione un documento"
            :options="documents"
            :allow-empty="false"
            @select="onSelectDocument"
          />
        </trazo-form-label-slot>

        <p class="my-4">Seleccione un archivo</p>
        <file-upload
          name="file-one"
          class="btn btn-primary"
          :disabled="sendingFiles"
          :extensions="extensions"
          :accept="accept"
          :multiple="false"
          @input-file="setSingleFile"
          ref="upload-one"
        >
          <i class="fa fa-plus"></i>
          Seleccionar
        </file-upload>
        <p v-if="singleFile">{{ singleFile.name }}</p>
      </b-modal>
      <b-form-input
        v-show="!showDocuments"
        class="mb-4"
        id="filter-input"
        v-model="filter"
        type="search"
        placeholder="Escriba para buscar"
      ></b-form-input>
      <b-table
        v-show="!showDocuments"
        :filter="filter"
        @filtered="onFiltered"
        head-variant="dark"
        :items="employees"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #cell(full_name)="data">
          {{ data.item.full_name }}- {{ data.item.id }}
        </template>
        <template #cell(actions)="data">
          <div class="h5 mb-0">
            <label
              size="sm"
              v-b-modal.modal-select-single-file
              variant="light"
              class="btn btn-light mr-2"
              @click="currentEmployee = data.item.id"
            >
              <b-icon icon="upload"></b-icon>
            </label>
            <label
              v-if="employeeHasPayroll(data.item.id)"
              size="sm"
              variant="light"
              class="btn btn-light mr-2"
              @click="showDocumentsOfUser(data.item.id)"
            >
              <b-icon icon="eye"></b-icon>
            </label>
            <label
              v-if="employeeHasPayroll(data.item.id)"
              size="sm"
              for="file-one"
              variant="light"
              class="btn btn-light mr-2"
              @click="currentEmployee = data.item.id"
            >
              <b-icon icon="pencil"></b-icon>
            </label>
            <label
              v-if="employeeHasPayroll(data.item.id)"
              size="sm"
              variant="light"
              class="btn btn-light"
              @click="removePayroll(data.item.id)"
            >
              <b-icon icon="trash"></b-icon>
            </label>
          </div>
        </template>
      </b-table>
      <div class="text-center" v-if="employees.length === 0">
        No existen empleados en esta empresa
      </div>
    </div>
    <documents-by-employee
      v-if="showDocuments"
      :employee="currentEmployee"
      v-on:updateShowDocuments="updateShowDocuments"
      :enterprise="enterprise"
    />
    <portal v-if="!showDocuments" to="pagination_assing_employee_documents">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
    </portal>
  </fragment>
</template>

<style lang="scss" scoped>
.payrollTableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
</style>

<script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import ImageCompressor from "@xkeshi/image-compressor";
import TrazoFormLabelSlot from "../../../content/TrazoFormLabelSlot.vue";
import DocumentsByEmployee from "./DocumentsByEmployee";

import ApiService from "@/core/services/api.service";

export default {
  props: ["enterprise", "employees", "payrolls", "period"],
  data() {
    return {
      currentEmployee: {},
      sendingFiles: false,
      currentPage: 1,
      perPage: 12,
      items: [],
      fields: [
        {
          key: "full_name",
          sortable: true,
          label: "Nombre"
        },
        {
          key: "actions",
          sortable: true,
          label: "Acciones"
        }
      ],
      enterprises: [],
      loadingEmployees: false,
      isTouchedForm: false,
      enterprise_hidden: "",
      isTableBaseEmployeeVisible: false,
      fv: null,

      isVisibleAssignEmployeeDocuments: false,
      files: [],
      file: {},
      accept: ".pdf",
      extensions: ["pdf"],
      minSize: 1024,
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      addIndex: false,
      thread: 3,
      name: "file",
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      isOption: false,
      loadingPeriod: false,
      documents: [],
      document: {},
      singleFile: null,

      filter: null,
      rows: 0,

      showDocuments: false
    };
  },
  computed: {},
  methods: {
    updateShowDocuments(value) {
      this.showDocuments = value;
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    onSelectDocument(document) {
      this.document = document;
    },
    showDocumentsOfUser(employee_id) {
      this.currentEmployee = employee_id;
      this.showDocuments = true;
    },
    getDocuments() {
      ApiService.get(`/api/document/other_document`).then(({ data }) => {
        this.documents = data;
        if (data.length !== 0) {
          this.document = data[0];
        }
      });
    },
    employeeHasPayroll(employee_id) {
      return (
        this.custom_lodash.findIndex(this.payrolls, {
          employee: employee_id
        }) !== -1
      );
    },
    async removePayroll(employee_id) {
      const payroll = this.custom_lodash.find(this.payrolls, {
        employee: employee_id
      });
      try {
        await ApiService.delete(`/api/payroll/${payroll.id}`);
        this.$emit("onUploadPayrollsFile");
        this.$bvToast.toast("Archivo eliminado satisfactoriamente.", {
          title: `Éxito`,
          variant: "succes",
          solid: true
        });
      } catch (error) {
        this.$bvToast.toast(
          "Ha ocurrido un error, no se pudo eliminar el archivo.",
          {
            title: `Error`,
            variant: "danger",
            solid: true
          }
        );
      }
    },
    employeeHasPayrollAdminSigned(employee_id) {
      const payroll = this.custom_lodash.find(this.payrolls, {
        employee: employee_id
      });
      return payroll?.admin_signed;
    },
    donwloadPayroll(employee_id, type) {
      const payroll = this.custom_lodash.find(this.payrolls, {
        employee: employee_id
      });
      this.downloadFile(payroll[type]);
    },
    async signPayroll(employee_id) {
      const payroll = this.custom_lodash.find(this.payrolls, {
        employee: employee_id
      });
      if (payroll) {
        try {
          await ApiService.get(
            `/api/payroll/${payroll.id}/sign/admin`,
            `?enterprise=${this.enterprise.id}`
          );
          this.$emit("onUploadPayrollsFile");
          this.$bvToast.toast("Boleta firmada correctamente.", {
            title: `Éxito`,
            variant: "succes",
            solid: true
          });
        } catch (error) {
          this.$bvToast.toast(
            "Ha ocurrido un error, no se pudo eliminar el archivo.",
            {
              title: `Error`,
              variant: "danger",
              solid: true
            }
          );
        }
      } else {
        this.$bvToast.toast("La boleta no existe, intente con otra boleta.", {
          title: `Error`,
          variant: "danger",
          solid: true
        });
      }
    },
    employeeHasPayrollEmployeeSigned(employee_id) {
      const payroll = this.custom_lodash.find(this.payrolls, {
        employee: employee_id
      });
      return payroll?.employee_signed;
    },
    downloadFile(url) {
      window.open(url, "_blank");
    },
    setEnterprise(enterprise) {
      this.enterprise = enterprise;
    },
    cancelUpload() {
      this.$emit("onUploadPayrollsFile");
      this.isTableBaseEmployeeVisible = true;
      this.isVisibleAssignEmployeeDocuments = false;
      this.files = [];
    },
    setSingleFile(file) {
      this.singleFile = file;
    },
    uploadSingleFile() {
      const formData = new FormData();
      formData.append("original", this.singleFile.file, this.singleFile.name);
      formData.append("period", this.period.id);
      formData.append("document", this.document.id);
      formData.append("employee", this.currentEmployee);
      formData.append("is_active", true);
      ApiService.post(`/api/payroll/`, formData, {
        headers: {
          "Content-Type": " multipart/form-data"
        }
      })
        .then(() => {
          this.singleFile = null;
          this.$bvToast.toast("Archivo agregado satisfactoriamente", {
            title: `Éxito`,
            variant: "succes",
            solid: true
          });
          this.$emit("onUploadPayrollsFile");
        })
        .catch(() => {
          this.$bvToast.toast(
            "Ha ocurrido un error, revise si es el archivo correcto.",
            {
              title: `Error`,
              variant: "danger",
              solid: true
            }
          );
        });
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
        // Automatic compression
        // 自动压缩
        if (
          newFile.file &&
          newFile.type.substr(0, 6) === "image/" &&
          this.autoCompress > 0 &&
          this.autoCompress < newFile.size
        ) {
          newFile.error = "compressing";
          const imageCompressor = new ImageCompressor(null, {
            convertSize: Infinity,
            maxWidth: 512,
            maxHeight: 512
          });
          imageCompressor
            .compress(newFile.file)
            .then(file => {
              this.$refs.upload.update(newFile, {
                error: "",
                file,
                size: file.size,
                type: file.type
              });
            })
            .catch(err => {
              this.$refs.upload.update(newFile, {
                error: err.message || "compress"
              });
            });
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        // 创建 blob 字段
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails
        // 缩略图
        newFile.thumb = "";
        if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
          newFile.thumb = newFile.blob;
        }
      }
    }, // add, update, remove File Event
    inputFile(newFile) {
      console.log("newFile", newFile);
    }
  },
  mounted() {
    this.getDocuments();
    this.rows = this.employees.length;
  },
  components: {
    TrazoFormLabelSlot,
    DocumentsByEmployee
  }
};
</script>
