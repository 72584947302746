<template>
  <fragment>
    <b-button class="mb-2" @click="$emit('updateShowDocuments', false)"
      >Volver a lista de empleados</b-button
    >
    <b-form-input
      class="mb-4"
      id="filter-input"
      v-model="filter"
      type="search"
      placeholder="Escriba para buscar"
    ></b-form-input>
    <b-table
      :filter="filter"
      @filtered="onFiltered"
      head-variant="dark"
      :items="documents"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(actions)="data">
        <div class="h5 mb-0">
          <label
            size="sm"
            variant="light"
            class="btn btn-light  mr-2"
            @click="downloadFile(data.item.original)"
          >
            <b-icon icon="eye"></b-icon>
          </label>
          <label
            size="sm"
            variant="light"
            class="btn btn-light"
            @click="removeDocument(data.item)"
          >
            <b-icon icon="trash"></b-icon>
          </label>
          <label
            v-if="data.item.require_employee_sign"
            size="sm"
            variant="light"
            class="btn btn-light ml-2"
            @click="signPayroll(data.item.id)"
          >
            <b-icon icon="vector-pen"></b-icon>
          </label>
        </div>
      </template>
    </b-table>
    <div class="text-center" v-if="documents.length === 0">
      El empleado no posee documentos
    </div>
    <portal to="pagination_assing_employee_documents">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
    </portal>
  </fragment>
</template>

<style lang="scss" scoped>
.payrollTableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
</style>

<script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import ApiService from "@/core/services/api.service";

export default {
  props: ["employee", "enterprise"],
  data() {
    return {
      currentEmployee: {},
      sendingFiles: false,
      currentPage: 1,
      perPage: 12,
      items: [],
      fields: [
        {
          key: "document_description",
          sortable: true,
          label: "Descripción"
        },
        {
          key: "actions",
          sortable: true,
          label: "Acciones"
        }
      ],
      enterprises: [],
      isVisibleAssignEmployeeDocuments: false,
      documents: [],
      showDocuments: false
    };
  },
  computed: {
    rows() {
      return this.documents.length;
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    onSelectDocument(document) {
      this.document = document;
    },
    getDocuments() {
      ApiService.get(`/api/user/${this.employee}/documents`).then(
        ({ data }) => {
          this.documents = data;
          console.log("this.documents", this.documents);
          this.rows = this.documents.length;
        }
      );
    },
    donwloadPayroll(employee_id) {
      const payroll = this.custom_lodash.find(this.documents, {
        employee: employee_id
      });
      this.downloadFile(payroll);
    },
    downloadFile(url) {
      window.open(url, "_blank");
    },
    async signPayroll(payroll_id) {
      try {
        await ApiService.get(
          `/api/payroll/${payroll_id}/sign/admin`,
          `?enterprise=${this.enterprise.id}`
        );
        this.$bvToast.toast("Boleta firmada correctamente.", {
          title: `Éxito`,
          variant: "succes",
          solid: true
        });
      } catch (error) {
        this.$bvToast.toast(
          "Ha ocurrido un error, no se pudo eliminar el archivo.",
          {
            title: `Error`,
            variant: "danger",
            solid: true
          }
        );
      }
    },
    async removeDocument(item) {
      try {
        await ApiService.delete(`/api/payroll/${item.id}`);
        this.getDocuments();
        this.$bvToast.toast("Archivo eliminado satisfactoriamente.", {
          title: `Éxito`,
          variant: "succes",
          solid: true
        });
      } catch (error) {
        this.$bvToast.toast(
          "Ha ocurrido un error, no se pudo eliminar el archivo.",
          {
            title: `Error`,
            variant: "danger",
            solid: true
          }
        );
      }
    }
  },
  mounted() {
    this.getDocuments();
  },
  components: {}
};
</script>
